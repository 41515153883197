// components/FloatingNavigation.jsx
import React from 'react';
import { FaChevronDown, FaChevronUp, FaList } from 'react-icons/fa';
import '../css/Buttons.css';

const FloatingNavigation = ({
  currentSectionIndex,
  totalSections,
  onPrevious,
  onNext,
  onHome,
}) => {
  return (
    <div className="floating-navigation">
      {/* Previous Button 
      <button
        onClick={onPrevious}
        disabled={currentSectionIndex === 0}
        className={`floating-nav-btn ${currentSectionIndex === 0 ? 'disabled' : ''}`}
        style={{ borderRight:'none'}}
      >
        <FaChevronUp />
      </button>
      */}

      {/* Home Button */}
      <button 
        onClick={onHome}
        className="floating-nav-btn"
      >
        <FaList />
      </button>

      {/* Next Button 
      <button
        onClick={onNext}
        disabled={currentSectionIndex === totalSections - 1}
        className={`floating-nav-btn ${
          currentSectionIndex === totalSections - 1 ? 'disabled' : ''
        }`}
        style={{ borderLeft:'none'}}
      >
        <FaChevronDown />
      </button>
      */}
    </div>
  );
};

export default FloatingNavigation;
