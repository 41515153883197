import React, { useEffect, useState, useRef } from "react";
import "../css/ProjectPage.css";
import Lottie from "lottie-react";
import { FaTimes } from "react-icons/fa";

const ImageSlide = ({
  imageSlideTitle,
  imageSlideImage,
  imageSlideCaption,
  style,
  animationData,
  showTitle = true,
  showImage = true,
  showCaption = true,
  showAnimation = false,
}) => {
  const [isInView, setIsInView] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const imageGroupRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.2 }
    );

    if (imageGroupRef.current) {
      observer.observe(imageGroupRef.current);
    }

    return () => {
      if (imageGroupRef.current) {
        observer.unobserve(imageGroupRef.current);
      }
    };
  }, []);

  const openFullscreen = () => setIsFullscreen(true);
  const closeFullscreen = () => setIsFullscreen(false);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      closeFullscreen();
    }
  };

  useEffect(() => {
    if (isFullscreen) {
      document.addEventListener("keydown", handleKeyDown);
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = ""; // Restore scrolling
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = ""; // Restore scrolling on unmount
    };
  }, [isFullscreen]);

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("fullscreen-overlay")) {
      closeFullscreen();
    }
  };

  return (
    <>
      <div className="image-section">
        {showTitle && <div className="image-slide-title">{imageSlideTitle}</div>}

        <div
          className={`image-slide-image-group ${isInView ? "in-view" : ""}`}
          ref={imageGroupRef}
        >
          {showImage && (
            <div className="image-slide-image" style={style} onClick={openFullscreen}>
              <img src={imageSlideImage} alt={imageSlideTitle} />
            </div>
          )}

          {showCaption && (
            <div className="image-slide-caption">{imageSlideCaption}</div>
          )}

          {showAnimation && (
            <div className="image-slide-animation">
              <Lottie animationData={animationData} style={style} />
            </div>
          )}
        </div>
      </div>

      {isFullscreen && (
        <div className="fullscreen-overlay" onClick={handleOverlayClick}>
          <FaTimes className="close-icon" onClick={closeFullscreen} />
          <div className="fullscreen-content">
            <img src={imageSlideImage} alt={imageSlideTitle} />
          </div>
        </div>
      )}
    </>
  );
};

export default ImageSlide;
