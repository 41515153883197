import React, { useState, useEffect } from 'react';
import '../css/Navbar.css';
import { ReactComponent as Logo } from '../assets/paulo-bastos-logo-contracted-horizontal.svg';
import { FaEnvelope, FaLinkedinIn, FaCopy, FaCheck } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [isVisible, setIsVisible] = useState(true); // Track navbar visibility
  const [lastScrollY, setLastScrollY] = useState(0); // Track last scroll position
  const [tooltipContent, setTooltipContent] = useState(null); // Tooltip content

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Hide navbar if scrolling down and past 100px, show if scrolling up
      setIsVisible(currentScrollY <= 100 || currentScrollY < lastScrollY);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText('contact@pvbastos.com'); // Copy to clipboard

    // Show confirmation tooltip
    setTooltipContent(
      <div>
        Email copied! <FaCheck style={{ color: 'green', paddingLeft: '4px' }} />
      </div>
    );

    // Reset tooltip content after 2 seconds
    setTimeout(() => {
      setTooltipContent(
        <div style={{ color: 'var(--button-label-tertiary-active)' }}>
          contact@pvbastos.com <FaCopy style={{ color: 'var(--button-label-tertiary-active)', paddingLeft: '4px' }} />
        </div>
      );
    }, 2000);
  };

  return (
    <nav className={`navbar ${isVisible ? '' : 'hidden'}`}>
      <div className="logo">
        <NavLink to="/">
          <Logo />
        </NavLink>
      </div>
      <div className="spacer"></div>
      <ul className="menu">
        <li>
          <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
            Work
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>
            About
          </NavLink>
        </li>
        <li>
          <div
            className="icon-container"
            onMouseEnter={() => setTooltipContent(
              <div style={{ color: 'var(--button-label-tertiary-active)' }}>
                contact@pvbastos.com <FaCopy style={{ color: 'var(--button-label-tertiary-active)', paddingLeft: '4px' }} />
              </div>
            )}
            onMouseLeave={() => setTooltipContent(null)}
            onClick={handleCopyToClipboard}
          >
            <FaEnvelope />
            {tooltipContent && <div className="tooltip">{tooltipContent}</div>}
          </div>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/pvbastos/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
