import React, { useState } from 'react';
import '../css/Project.css';
import ButtonLarge from '../components/Button-large';
import { useNavigate } from 'react-router-dom';

const Project = ({ title, description, image, imageAlt, buttonLabel, to, disabled }) => {
  const navigate = useNavigate();

  // State to manage hover
  const [isHovered, setIsHovered] = useState(false);

  // Function to handle navigation
  const handleNavigate = () => {
    if (!disabled) {
      navigate(to);
    }
  };

  return (
    <div
      className="project"
      
    >
      <div className="project-menu">
        <div
          style={{  }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleNavigate}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer', backgroundImage: `url(${image})`}}
          className={`project-cover ${isHovered ? 'hovered' : ''}`}
        />
        <div
          className={`project-info ${isHovered ? 'hovered' : ''}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleNavigate}
          style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        >
          <h3 className={isHovered ? 'hovered' : ''}>{title}</h3>
          <p>{description}</p>
          <ButtonLarge
            className="button-large"
            label={buttonLabel}
            setIsButtonHovered={setIsHovered} // Pass the hover function
            to={to}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Project;

